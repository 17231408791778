<template>
	<div class="withdraw qjc-fts-34">
		<van-nav-bar
			title="提现账户信息"
			left-arrow
			class="qjc-agent-navbar"
			@click-left="$router.back()"
		></van-nav-bar>
		
		<p class="notice qjc-fts-26">請確保輸入的信息準確，資金一旦轉出將無法追回</p>
		
		<div class="form">
			<van-field
				v-model="bank"
				label="收款銀行"
				placeholder="請輸入收款銀行"
			></van-field>
			
			<van-field
				v-model="payee"
				label="收款人"
				placeholder="請輸入收款人"
			></van-field>
			
			<van-field
				v-model="account"
				label="收款賬戶"
				@input="accountFormat"
				@blur="belongBank"
				placeholder="請輸入收款賬戶"
			></van-field>
			
			<van-field
				v-model="bankCode"
				label="國際銀行代碼"
				placeholder="請輸入代碼"
			></van-field>
			
		</div>
		
		<van-button @click="submit" :loading="loading" :disabled="loading" class="submit qjc-block qjc-bg-primary qjc-c-fff qjc-fts-32 qjc-ftw-n">
			<span class="qjc-vertical-m">確認</span>
			<img class="qjc-vertical-m" src="../../../assets/images/agent_login_arrow.png" alt="">
		</van-button>
		
		<p class="tip">每月5號結算上月收入，预计7个工作日内发放上月收益至您的银行卡</p>
		
	</div>
</template>

<script>
	import Vue from 'vue'
	import { NavBar, Field, Button, Toast, Dialog } from 'vant'
	Vue.use(NavBar)
	   .use(Field)
	   .use(Button)
	   .use(Toast)
	   .use(Dialog);
	
	import bankCardAttribution from '@/js/bankCardAttribution'
	
	export default{
		name: "agentEarningsWithdraw",
		data() {
			return {
				bank: '',
				payee: '',
				account: '',// 中间含有空格间隔，使用需清除
				bankCode: '',
				
				loading: false
			}
		},
		methods: {
			submit() {// 提交
				if(!this.bank.trim()){
					Dialog.alert({ message: '請輸入收款銀行' });
				}else if(!this.payee.trim()){
					Dialog.alert({ message: '請輸入收款人' });
				}else if(!this.account.trim()){
					Dialog.alert({ message: '請輸入收款賬戶' });
				}else if(!this.bankCode.trim()){
					Dialog.alert({ message: '請輸入國際銀行代碼' });
				}else{
					console.log('s')
				}
			},
			
			// 所属银行(自动检测)
			belongBank() {
				var belong = bankCardAttribution(this.account.replace(/\D/g, ''));
				if(belong != 'error'){
					if(this.bank && this.bank != belong.bankName){// 如填写过收款银行并与检测名不一致时，提示
						Dialog.confirm({
							title: '收款銀行確認',
							message: '是否更改收款銀行為：'+belong.bankName,
							confirmButtonText: '是',
							cancelButtonText: '否'
						})
						.then(() => {
							// on confirm
							this.bank = belong.bankName;
						})
						.catch(() => {
							// on cancel
						});
					}else{
						this.bank = belong.bankName;
					}
				}
			},
			
			// 账户格式
			accountFormat(val) {
				// 只能为数字,且最大19位
				var value = val.replace(/\D/g, '').slice(0, 19);
				
				// 每间隔4位数留一空格
				for(var i=0,str='';i<Math.ceil(value.length/4);i++){
					str += value.slice(i*4, (i+1)*4) + ' ';
				}
				
				this.account = str.slice(0, -1);
				
			}
		}
	}
</script>

<style scoped>
	.form >>> .van-field__body{
		height: 100%;
	}
	.form >>> .van-field__control{
		color: #3B4549;
		font-weight: 500;
		text-align: right;
	}
	.form >>> input::placeholder{
		font-weight: 400;
	}
	.form >>> .van-field__label{
		width: auto;
	}
</style>
<style lang="scss" scoped>
	.withdraw{
		color: #8C9699;
		
		.notice{
			line-height: 0.72rem;
			background-color: #EBEDF5;
		}
		
		.form{
			margin: 0.32rem 0.24rem;
			
			.van-field{
				border: 0.01rem solid #E1E3EB;
				border-radius: 0.08rem;
				font-size: 0.3rem;
				color: #8C9699;
				text-align: left;
				padding: 0.19rem 0.24rem;
				margin-bottom: 0.2rem;
			}
			
		}
		
		.submit{
			width: 7.02rem;
			height: 0.87rem;
			line-height: 1;
			border-radius: 0.08rem;
			margin: 0.68rem auto 0.18rem;
			padding: 0 0 0 0.2rem;
			
			img{
				width: 0.59rem;
				height: auto;
				margin: -0.1rem 0 0 0.11rem;
			}
		}
		
		.tip{
			font-size: 0.22rem;
			padding: 0 0.24rem;
		}
		
	}
</style>
